import {defineStore} from 'pinia'
import {computed, ref} from 'vue'
import type {Notification, User} from '~/types'

export const useAuthStore = defineStore('auth', () => {
    const { $i18n } = useNuxtApp()
    const { setLocale } = $i18n

    const authenticated = ref(false)
    const loading = ref(false)
    const user = ref<User | null>(null)
    const showSidebar = ref(true)

    const getUnreadNotificationsCount = computed(() => {
        return user.value?.notifications.filter(notification => !notification.read_at).length
    })

    const hasUnreadNotifications = computed(() => {
        return user.value?.notifications.some(notification => !notification.read_at)
    })

    function unimpersonate() {
        const impToken = useCookie('imp_token')
        impToken.value = null

        reloadNuxtApp()
    }

    function isImpersonating() {
        return useCookie('imp_token').value !== null && useCookie('imp_token').value !== undefined
    }

    function setImpersonateToken(token: string) {
        const impToken = useCookie('imp_token')
        impToken.value = token

        reloadNuxtApp()
    }

    async function loadFreshNotifications() {
        if (user.value) {
            const newNotifications = await useAuthPost('notifications')
            user.value.notifications = newNotifications as Notification[]
        }
    }

    function setUser(newUser: User) {
        user.value = newUser
    }

    async function updateLocale(newLocale: string) {
        if (user.value) {
            user.value.langcode = newLocale
            await useAuthPost('user/update-locale', { method: 'post', body: { langcode: newLocale } })
            await getUser(true)
        }
        setLocale(newLocale)
    }

    async function getUser(force: boolean = false) {
        if (user.value === null || force) {
            if (getToken()) {
                try {
                    user.value = await useAuthPost('user')
                    if (user.value?.langcode) {
                        setLocale(user.value?.langcode)
                    }
                } catch {
                    logUserOut()
                    user.value = null
                }
            } else {
                user.value = null
            }
        }

        return user.value
    }

    function setToken(token: string) {
        useCookie('token').value = token
        authenticated.value = true
        getUser()
    }

    async function authenticateUser(payload: { email: string; password: string }) {
        loading.value = true
        return useAuthPost<{ token: string }>('login', {
            method: 'POST',
            body: {
                email: payload.email,
                password: payload.password,
            },
        })
            .then((data) => {
                const token = useCookie('token', { maxAge: 31536000 * 2 })
                if (data.token) {
                    token.value = data.token
                    authenticated.value = true
                    getUser()
                }
            })
            .finally(() => {
                loading.value = false
            })
    }

    function getToken() {
        return useCookie('token').value
    }

    function logUserOut() {
        try {
            const token = useCookie('token')
            authenticated.value = false
            user.value = null
            token.value = null
        }
        catch (e) {
            console.error(e)
        }

        if (window) {
            window.location.reload()
        }
    }

    return {
        authenticated,
        loading,
        user,
        showSidebar,
        hasUnreadNotifications,
        getUnreadNotificationsCount,
        unimpersonate,
        isImpersonating,
        setImpersonateToken,
        loadFreshNotifications,
        setUser,
        updateLocale,
        getUser,
        setToken,
        authenticateUser,
        getToken,
        logUserOut
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
